<template>
  <div class='c-DataTools'>
    <ado-left
      :id='id'
      :sliders='sliders'
      :portname='portname'
      :token='token'
      @changeid='changeidHandler'
    ></ado-left>
    <ado-right
      :id='id'
      :portname='portname'
      :token='token'
      v-if='showRight'
      :type='type'
    ></ado-right>
  </div>
</template>
<script>
import Vue from 'vue';
import { httpGet } from './assets/scripts/http';

import AdoLeft from './components/AdoLeft';
import AdoRight from './components/AdoRight';
import './plugins/index'
//
import GridDataHeaderSortRender from '~~components/GridList/GridDataHeaderSortRender';
import GridCellLinkRender from '~~components/GridList/GridCellLinkRender';
import GridCellSortRender from '~~components/GridList/GridCellSortRender';

Vue.component('GridDataHeaderSortRender', GridDataHeaderSortRender);
Vue.component('GridCellLinkRender', GridCellLinkRender);
Vue.component('GridCellSortRender', GridCellSortRender);

// const testData = {
//   Category: 0,
//   CreateTime: '2018-10-12 15:32:53',
//   Description: '说明：市场景气指数，反应市场整体的“生意好坏”。\n原理：对满足采样条件的贸易商24个月的销售数据进行分析，第一个月销售额为基准，最近24个月销售额相对变化百分比。\n权限：所有会员\n费用：免费',
//   ID: '5984b41d-b31a-4cd4-81b2-482769d7d152',
//   Name: '热门型号列表new',
//   isNew: false,
//   Sort: 1043,
// };
export default {
  name: 'DataTools',
  props: {
    token: String
  },
  components: {
    AdoLeft,
    AdoRight
  },
  data () {
    return {
      sliders: [],
      id: '',
      portname: '',
      showRight: true,
      activeId: '',
      type: 'old'
    };
  },
  methods: {
    changeidHandler (id, name, type) {
      this.id = id;
      this.portname = name;
      this.type = type;
    },
    getConfigs () {
      httpGet(this.token1, 'report/configs').then(data => {
        // data.unshift(testData);
        if (data.length) {
          data.forEach(item => {
            if (item.Name.indexOf('new') !== -1) {
              item.type = 'new';
              item.Name = item.Name.replace('new', '');
            } else {
              item.type = 'old';
            }
          });
        }
        this.sliders = data || [];
        if (this.sliders.length > 0) {
          this.id = this.sliders[0].ID;
          this.portname = this.sliders[0].Name;
          this.type = this.sliders[0].type || 'old';
        }
      });
    }
  },
  mounted () {
    this.getConfigs();
  }
};
</script>
<style lang='scss'>
.c-DataTools {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  overflow-y: hidden;
  font-size: 14px;
}

.znl-cell-header .header-label,
.znl-table-header_element-cell.table-selection .cell::before {
  background-color: $--color-table-header;
  color: $--fontcolor-dark-3;
  font-weight: 900;
}
</style>

