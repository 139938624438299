import axios from 'axios';
import { CONFIG } from './config';
// import Message from 'element-ui/lib/message.js';
import { $erroMess } from './utils/utils';

const DEBUG = process.env.NODE_ENV !== 'production';
const headers = {
  'Content-Type': 'application/json'
};

async function httpHandler (method, TOKEN, url, data, option) {
  if (TOKEN) {
    Object.assign(headers, {
      Authorization: 'JWT ' + TOKEN
    });
  }
  let AXIOSRES = null;
  let baseURL = CONFIG.API_URL;
  if (option && option.baseURL === 'res') {
    baseURL = CONFIG.RES_URL;
  }
  if (option && option.baseURL === 'YUN') {
    baseURL = CONFIG.YUN_URL;
  }
  try {
    AXIOSRES = await axios.request({
      baseURL,
      headers,
      method,
      url,
      params: method === 'get' ? data : null,
      data: method === 'post' ? data : null,
      timeout: DEBUG ? 100000 : 30000
    });

    if (AXIOSRES.status !== 200 || AXIOSRES.data.Code !== 200) {
      throw new Error('网络异常，请稍后再试');
    } else {
      const Result = AXIOSRES.data.Result;
      if (Result.Code === 0 || AXIOSRES.data.Code === 200) {
        return Result.Data || AXIOSRES.data;
      } else if (option.baseURL === 'YUN' && Result.Data) {
        return Result.Data;
      } else {
        throw new Error(Result.Message);
      }
    }
  } catch (error) {
    $erroMess(error.message);
  }
}

function httpGet (TOKEN, url, data, option) {
  return httpHandler('get', TOKEN, url, data, option);
}

function httpPost (TOKEN, url, data, option) {
  return httpHandler('post', TOKEN, url, data, option);
}

export { httpHandler, httpGet, httpPost };
