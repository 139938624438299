import DataTools from './DataTools.vue';
// const DataTools = require('./DataTools.vue').default;

/* istanbul ignore next */
DataTools.install = function(Vue) {
  if (Vue) {
    Vue.component(DataTools.name, DataTools);
    // Vue.component('v-chart', VueECharts);
  }
};

const install = function(Vue) {
  Vue.component(DataTools.name, DataTools);
  // Vue.component('v-chart', VueECharts);
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default DataTools;

