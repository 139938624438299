<template>
  <div class="c-PageLayout">
    <div class="c-PageLayout_title">
      {{title}}
      <i
        class="iconfont icon-title_tips"
        :title="tip"
        v-if="tip || tipHandler"
        @click.stop="() => {tipHandler ? tipHandler() : null}"
        @mouseenter.stop="() => {tipEnter ? tipEnter() : null}"
        @mouseout.stop="() => {tipOut ? tipOut() : null}"
      ></i>
      <slot name="title"></slot>
      <slot name="title-right"></slot>
    </div>
    <div class="c-PageLayout_body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
  props: {
    title: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    tipHandler: Function,
    tipEnter: Function,
    tipOut: Function
  }
};
</script>

<style lang="scss" scoped>
.c-PageLayout {
  .c-PageLayout_title {
    font-size: $--font-size-medium;
    color: $--fontcolor-title;
    font-weight: 900;
    padding: 5px 0;
    .iconfont {
      color: $--color-main;
      cursor: pointer;
    }
  }
}
</style>

