const columns = [
  // {
  //   field: '排名',
  //   width: 150,
  //   headerName: '总排名(最近7天)',
  //
  //   cellClass: params => {
  //     let value = params.value;
  //     let className = '';
  //     if (value === 1) {
  //       className = 't-c c-circle c-circle_d9001b';
  //     } else if (value === 2) {
  //       className = 't-c c-circle c-circle_ee7700';
  //     } else if (value === 3) {
  //       className = 't-c c-circle c-circle_f9bf24';
  //     } else {
  //       className = 't-c';
  //     }
  //     return className;
  //   }
  // },
  {
    field: 'sort',
    headerName: '序号',
    width: 50,
    cellClass: 't-c c-background'
  },
  {
    field: 'RankInt',
    headerName: '排名',
    width: 170,
    headerComponentFramework: 'GridDataHeaderSortRender',
    cellRendererFramework: 'GridCellRankInt',
    cellClass: () => {
      return 'c-flex-box';
    }
  },
  {
    field: 'Keyword',
    headerName: '型号',
    cellClass: 't-l',
    cellRendererFramework: 'GridCellLinkRender'
  },
  {
    field: 'Brand',
    headerName: '参考品牌',
    cellClass: 't-l c-background'
  },
  {
    field: 'SearchCnt',
    headerName: '搜索次数',
    cellClass: 't-l c-background'

  },
  {
    field: 'SearchCompanyCnt',
    headerName: '搜索公司数',
    cellClass: 't-l c-background'
  }
];
export {
  columns
};
