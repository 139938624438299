<template>
  <div class='c-grid-cell-link'>
    <el-dropdown>
      <div @click='onClick' class='text'>{{ params.value }}</div>
      <el-dropdown-menu slot='dropdown'>
        <el-dropdown-item>查看型号详情</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

  </div>
</template>
<script>
import Vue from 'vue';

import { Tooltip } from 'element-ui';

Vue.use(Tooltip);


export default {
  name: 'GridCellLinkRender',
  data () {
    return {};
  },
  methods: {
    onClick () {
      const url = this.params.data['URL'];
      window.open(url, '_blank');
    }
  },
  created () {

  }
};
</script>
<style lang='scss'>
.c-grid-cell-link {
  .el-dropdown {
    font-size: 12px;
    color: #000000
  }
  .text {
    cursor: pointer;
    text-decoration: underline;
  }

  .text:hover {
    color: #ee7700;
  }

}
</style>
