<template>
  <div class='p-AdoRight'>
    <znl-ado ref='znlado'
             :id='id'
             :portname='portname'
             :ado-config='adoConfig'
             :display-default.sync='displayDefault'
             :type='type'
             :token='token'>
    </znl-ado>
  </div>
</template>
<script>
import ZnlAdo from '../components/ZnlAdo';
import { httpGet } from '../assets/scripts/http';

export default {
  name: 'Ado',
  components: {
    ZnlAdo
  },
  data () {
    return {
      adoConfig: {},
      displayDefault: 0,
      Description: []
    };
  },
  props: {
    type: {
      type: String,
      default () {
        return 'old';
      }
    },
    id: {
      type: String,
      default: ''
    },
    portname: {
      type: String,
      default: ''
    },
    token: String
  },
  watch: {
    id: {
      deep: true,
      handler: async function(id) {
        if (id) {
          this.adoConfig = {}
          this.$refs.znlado.refreshPrarms();
          httpGet(this.token, `report/config/${id}`)
            .then(data => {
              if (data) {
                if (data.Description) {
                  this.Description = data.Description.split(/\n/);
                }
                this.adoConfig = Object.assign({}, data, {
                  Description: this.Description
                });
                if (this.type === 'new') {
                  this.$refs.znlado.$refs.modulesView.onModuleInit(this.adoConfig);
                  return false;
                }
                this.displayDefault = data.DisplayDefault || 99999;
                this.$refs.znlado.defaultHandler(this.adoConfig);
              }
            })
            .catch(() => {

            });
        }
      }
    }
  }
};
</script>
<style lang='scss'>
.p-AdoRight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 160px;
  right: 0;
  padding: 10px;
  overflow-y: auto;

  & > div {
    background-color: $--color-bg-model;
    height: 100%;
  }

  .p-ZnlAdo {
    padding: 5px;
  }
}
</style>

