import { filterDate } from '~~utils/utils';
import { httpPost } from '~~assets/scripts/http';

export let newData = {
  echarts_data_1: [],
  echarts_data_2: [],
  echarts_data_3: [], // 搜索公司数走势
  echarts_data_4: [] //搜索量排名走势
};

const getData = async (kw, typeIndex, token) => {
  let params = {
    PartNo: kw,
    DateType: typeIndex
  };
  let res = null;
  try {
    res = await httpPost(token, `/hugedatatrend`, params, { baseURL: 'YUN' });
  } catch (e) {
    throw e;
  }
  return res;

};

export const setData = async (kw, typeIndex, token) => {
  let data = {
    SearchCountTrend: [],
    CompanyCountTrend: [],
    PriceTrend: [],
    PartRankTrend: []
  };
  const res = await getData(kw, typeIndex, token);
  if (!res) {
    return null;
  }
  Object.keys(data).forEach(key => {
    if (res[key]) {
      data[key] = res[key];
    }
  });
  const searchCountTrend = filterData(data['SearchCountTrend'], typeIndex);
  const companyCountTrend = filterData(data['CompanyCountTrend'], typeIndex);
  const priceTrend = filterData(data['PriceTrend'], typeIndex);
  const partRankTrend = filterData(data['PartRankTrend'], typeIndex);

  newData.echarts_data_1 = searchCountTrend;
  newData.echarts_data_2 = priceTrend;
  newData.echarts_data_3 = companyCountTrend;
  newData.echarts_data_4 = partRankTrend;

  return newData;
};
const filterData = (list, typeIndex) => {
  let newList = [];
  if (list && list.length > 0) {
    newList = list.map(item => {
      if (typeIndex === 2) {
        item['DateValue'] = filterDate(item['DateValue'], 'yyyy-MM');
      }
      return [item['DateValue'], item['PriceOrCount']];
    });
  }

  return newList;
};