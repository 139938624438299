<template>
  <div class='p-modules'>
    <section class='p-modules-header' v-if='type === "new"'>
      <div class='p-modules-header_title'>
        <span>{{ textItem.title }}</span>
      </div>
    </section>
    <section class='p-modules-options' v-if='type === "new"'>
      <div class='p-modules-options_search'>
        <div class='p-modules-options_input' v-for='item in optionItem.searchValueList' :key='item.key'
             @keydown.enter='onResSearchData'>
          <el-input clearable @clear='onResSearchData' v-model='item.value' :placeholder='item.placeholder'>
          </el-input>
        </div>
        <el-button @click='onResSearchData'>搜索</el-button>
        <div
          class="download-button"
          @click='onDownFile'>
          <span style="text-decoration: underline;">下载报告</span>
          <span class="download-button-tips">(报告已包含封装信息)</span>
        </div>
      </div>


      <div class='p-modules-options_btn' style='display: flex; align-items: center'>
        <div class='p-modules-options_kj' v-if='selectType !== "day"'>
          <section class='item' @click='onChangeTab(item)' v-for='item in tabList' :key='item.key'
                   :class='item.key === tabActive? "item_active": ""'>
            <div>{{ item.value }}</div>
          </section>
        </div>
        <el-date-picker
          v-if="selectType === 'week'"
          type='week'
          v-model='weekDate'
          @change='onChangeWeek'
          :picker-options='pickerOptions'
          format='yyyy 第 WW 周'
          placeholder='选择周'>
        </el-date-picker>
        <el-date-picker
          type='month'
          v-if="selectType === 'month'"
          @change='onChangeMonth'
          v-model='monthDate'
          placeholder='选择月'>
        </el-date-picker>
        <el-radio-group @change='onTypeChange' v-model='selectTypeValue' size='small' fill='#EE7700'>
          <el-radio-button label='近七天'></el-radio-button>
          <el-radio-button label='周数据'></el-radio-button>
          <el-radio-button label='月数据'></el-radio-button>
        </el-radio-group>
      </div>
    </section>
    <section class='p-modules-box'>
      <table-view
        :list='listItem.list'
        :pageSize='listItem.pageSize'
        :pageIndex='listItem.pageIndex'
        :pageTotal='listItem.pageTotal'
        :columns='listItem.columns'
        :loading='loading'
        @onSearch='onSearch'
        @onSort='onSort'
        @onOpen='onOpen'
        :selectType='selectType'
        :dateItems='dateItems'
        :loadingText='loadingText'
      ></table-view>
    </section>
    <dialog-view
      :token='token'
      :selectType='selectType'
      ref='dialogView' />
  </div>

</template>
<script>
import props from './config/props';
import tableView from './table.vue';

import { $erroMess, filterDate } from '~~utils/utils';

import DialogView from '~~components/modules/Diglog/DialogView.vue';
import moment from 'moment';
import modules from '~~components/modules/modules.js';

export default {
  name: 'modulesViews',
  mixins: [props, modules],
  components: {
    tableView,
    DialogView
  },
  data () {
    return {
      boxView: 'tableView',
      selectTypeValue: '周数据',
      weekDate: '',
      monthDate: '',
      pickerOptions: {
        disabledDate (time) {
          const newDateNumber = time.getTime();
          // 时间限制
          const newDate = new Date();
          const weekOfDay = moment(newDate).format('E');
          const monday = moment(newDate).subtract(weekOfDay - 1, 'days').format('YYYY-MM-DD'); //获取周一
          const mondayDate = new Date(monday);
          const mondayNum = mondayDate.getTime();
          const endDate = mondayNum - 1000 * 60 * 60 * 24;
          return newDateNumber >= endDate;
        },
        firstDayOfWeek: 1
      },

      tabList: [
        {
          key: 'one',
          value: '最新一期'
        },
        {
          key: 'two',
          value: '最近第二期'
        },
        {
          key: 'three',
          value: '最近第三期'
        },
        {
          key: 'four',
          value: '最近第四期'
        }

      ],
      tabActive: 'one'
    };
  },
  methods: {
    // 添加tab 筛选
    async onChangeTab (item) {
      const isShow = await this.getPurchased();
      if (!isShow) return false;

      this.tabActive = item.key;

      const keyMap = {
        one: 1,
        two: 2,
        three: 3,
        four: 4
      };

      // 获取周一的时间
      if (this.selectType === 'week') {
        const { startTime, endTime } = this.setWeekDate(keyMap[item.key]);
        this.weekDate = startTime;
        await this.onResDate(startTime, endTime);
      }

      if (this.selectType === 'month') {
        const month = this.setMonthDate(keyMap[item.key]);
        this.monthDate = month;

        console.log(this.monthDate )

        const paramsMonth = filterDate(month, 'yyyy-MM');
        await this.onResDate('', '', paramsMonth);
      }

    },

    setWeekDate (key) {

      //处理时间
      const nowDate = new Date();

      const nowDateNum = nowDate.getTime();
      const nowDay = nowDate.getDay();

      const oneDayTime = 24 * 60 * 60 * 1000;

      // 获取周一的时间
      const mondayTime = nowDateNum - (nowDay - 1) * oneDayTime;

      const keyTime = key * oneDayTime * 7;

      let startTime = mondayTime - keyTime;
      let endTime = startTime + oneDayTime * 6;

      startTime = filterDate(new Date(startTime), 'yyyy-MM-dd');
      endTime = filterDate(new Date(endTime), 'yyyy-MM-dd');
      return {
        startTime: new Date(startTime),
        endTime: new Date(endTime)
      };
    },

    setMonthDate (key) {
      const nowDate = new Date();
      const nowMonth = nowDate.getMonth() + 1;
      const nowYear = nowDate.getFullYear();

      const keyMonth = nowMonth - key;

      console.log(keyMonth)

      return new Date(nowYear, keyMonth, 0);
    },

    async onTypeChange () {
      const selectTypeValue = this.selectTypeValue;
      this.searchData = {
        OrderBy: 0,
        // 搜索时间
        StartTime: '',
        EndTime: '',
        YearAndMonth: ''
      };
      let newDate = '';
      if (selectTypeValue === '周数据') {
        this.selectType = 'week';
        newDate = this.weekDate;
      }
      if (selectTypeValue === '月数据') {
        this.selectType = 'month';
        newDate = this.monthDate;
      }
      if (selectTypeValue === '近七天') {
        this.selectType = 'day';
      }

      this.tabActive = 'one';

      await this.onInitDate(this.selectType, newDate);
    },
    async onChangeWeek () {
      const isShow = await this.getPurchased();
      if (!isShow) return false;

      const date = this.weekDate;
      await this.onInitDate('week', date);
    },
    async onChangeMonth () {
      const date = this.monthDate;
      await this.onInitDate('month', date);
    },
    async onInitDate (type, date) {
      let startDate = '';
      let endDate = '';
      let month = '';
      let timeData = date || new Date();
      if (type === 'week') {

        const weekOfDay = moment(timeData).format('E'); //计算是这周第几天
        const monday = moment(timeData).subtract(weekOfDay - 1, 'days').format('YYYY-MM-DD'); //获取周一
        const sunday = moment(timeData).add(7 - weekOfDay, 'days').format('YYYY-MM-DD'); //获取周日
        startDate = new Date(monday);
        endDate = new Date(sunday);
      }
      if (type === 'month') {
        month = filterDate(date, 'yyyy-MM');
      }


      await this.onResDate(startDate, endDate, month);
    },
    async onResDate (startDate, endDate, month) {
      this.searchData.StartTime = startDate;
      this.searchData.EndTime = endDate;
      this.searchData.YearAndMonth = month;

      if (this.selectType === 'month') {
        delete this.searchData.StartTime;
        delete this.searchData.EndTime;
      }

      await this.onSearch(1);
    },

    onOpen (items) {
      const data = {
        Model: items['Keyword'],
        EndTime: '',
        YearAndMonth: '',
        WeekCount: items['WeekCount'],
        MonthCount: items['MonthCount']
      };
      this.$refs.dialogView.onOpen(data);
    },


    async getPurchased () {
      const isPay = await this.isPurchased();
      if (isPay === 'yes') {
        return true;
      } else if (isPay === 'no') {
        this.$msgbox({
          title: '联系我们',
          message: `<div style='line-height:28px;'>
                    <div class='items-center' >开通数据工具,请联系销售</div>
                    <div class='items-center' style='margin-top:8px;'>
                      销售咨询:
                      <span class='ml-r8'>400-699-2899(转1)</span>
                    </div>
                    <div class='items-center' style='margin-top:8px;'>
                      销售QQ:
                      <a class='jump' href='https://wpa.qq.com/msgrd?v=3&amp;uin=800106568&amp;site=qq&amp;menu=yes' target='_blank'>
                        <div class='QQ'></div>
                        <span>800106568</span>
                      </a>
                    </div>
                  </div>`,
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
          customClass: 'customBox'
        }).catch(() => {
        }).finally(() => {
          elMessageBox.style = null;
          this.$nextTick(() => {
            console.log(elMessageBox);
          });
        });
        this.loading = false;
        return false;
      } else {
        $erroMess(isPay);
        return false;
      }
    },
    async onResSearchData () {
      const isShow = await this.getPurchased();
      if (!isShow) return false;
      await this.onSearch(1);
    }

  },
  async created () {
    const newDate = new Date();
    const newDataNum = newDate.getTime();
    this.weekDate = newDataNum - 1000 * 60 * 60 * 24 * 7;
    this.monthDate = newDataNum - 1000 * 60 * 60 * 24 * 31;
  }
};
</script>
<style lang='scss'>
@import "./index.scss";
</style>
