export function GetQueryString () {
  // debugger;
  if (location.hash) {
    const url = location.hash.split('?')[1]; // 获取url中"?"符后的字串
    const theRequest = new Object();
    let strs = [];
    if (url) {
      strs = url.split('&');
      strs.forEach(item => {
        theRequest[item.split('=')[0]] = unescape(item.split('=')[1]);
      });
      // theRequest.href =  location.hash.split('?')[0].substr(2);
      return theRequest;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function toPercent (point) {
  return Number(point * 100).toFixed(2) + '%';
}

export const $succMess = (message, duration = 1500, onClose) => {
  ELEMENT.Message({
    message, customClass: 'bom_center_message', duration, type: 'success', onClose
  });
};
export const $erroMess = (message, duration = 2000) => {
  ELEMENT.Message({
    message, customClass: 'bom_center_message', duration, type: 'warning'
  });
};


export async function downloadFile (fileUrl) {
  if (window.cefQuery) {
    window.cefQuery({
      request: 'downloadFile',
      data: JSON.stringify({ FileUrl: fileUrl }),
      onSuccess: res => {
        console.log(res);
      },
      onFailure: (code, err) => {
        console.log(code, err, 'fail');
      },
      persistent: false
    });
  } else {
    (function() {
      let iframe = document.createElement('iframe');
      iframe.src = fileUrl;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    })();
  }


}

export const filterDate = (date, fmt) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  let dateData = new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, dateData.getFullYear() + '').substr(4 - RegExp.$1.length);
  }
  let o = {
    'M+': dateData.getMonth() + 1,
    'd+': dateData.getDate(),
    'h+': dateData.getHours(),
    'm+': dateData.getMinutes(),
    's+': dateData.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }

  function padLeftZero (str) {
    return ('00' + str).substr(str.length);
  }

  return fmt;
};
