<template>
  <div class='c-grid-sort'>
    <div>
      <span :class='className'>{{params.data['RankInt']}}</span>
    </div>
    <div>
      <el-dropdown placement='bottom-start'>
        <div>
          <i v-if='params.data["RankCompareLastWeek"] > 0'  class='c-sort-up  t-c el-icon-top'></i>
          <i v-if='params.data["RankCompareLastWeek"] < 0'  class='c-sort-down t-c el-icon-bottom'></i>
          <span :class='params.data["RankCompareLastWeek"] > 0 ? "c-grid-red":"c-grid-green" '>{{ params.data["RankCompareLastWeek"] === 0? '' : Math.abs(params.data["RankCompareLastWeek"])}}</span>
        </div>
        <el-dropdown-menu slot='dropdown'>
          <el-dropdown-item class='el-dropdown-item_test'>本期排名：第{{params.data['排名']}}（{{params.dateItems[1]}}-{{params.dateItems[0]}}）</el-dropdown-item>
          <el-dropdown-item class='el-dropdown-item_test'>上期排名：第{{params.data['上期排名']}}（{{params.dateItems[3]}}-{{params.dateItems[2]}}）</el-dropdown-item>
          <el-dropdown-item class='el-dropdown-item_test'>排名变化：<span :class='params.value > 0 ? "c-grid-red":"c-grid-green"  '>{{params.value > 0? '上涨' : '下跌'}}{{Math.abs(params.value)}}名</span></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GridCellSortRender',
  data () {
    return {
      className: ''
    };
  },
  created () {
    const sort = this.params.data['RankInt']
    const className = {
      1: 'c-circle c-circle_d9001b',
      2: 'c-circle c-circle_ee7700',
      3: 'c-circle c-circle_f9bf24'
    }
    // if (value === 1) {
    //   className = 't-c c-circle c-circle_d9001b';
    // } else if (value === 2) {
    //   className = 't-c c-circle c-circle_ee7700';
    // } else if (value === 3) {
    //   className = 't-c c-circle c-circle_f9bf24';
    // } else {
    //   className = 't-c';
    // }
    this.className = className[sort]
  }
};
</script>
<style lang='scss'>
.c-grid-sort {
  display: flex;
  justify-content: space-between;
  .el-dropdown {
    font-size: 12px;
  }

}
.c-grid-red {
  color: red;
}
.c-grid-green {
  color: green;
}

.el-dropdown-item_test {
  justify-content: left !important;
}
</style>
