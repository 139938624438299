export const commonConfig = {
  title: {
    text: '',
    textStyle: {
      fontSize: '14',
      align: 'center'
    }
  },
  backgroundColor: '#fff',
  color: [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1'
  ],
  toolbox: {
    show: true,
    // orient: 'vertical', // 工具栏位置
    itemSize: 16,
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      dataView: {
        readOnly: true
      },
      restore: {},
      saveAsImage: {}
    }
  },
  graphic: [
    {
      type: 'image',
      id: 'logo',
      // right: 'center',
      // top: 'center',
      left: 200,
      top: 100,
      z: -10,
      bounding: 'raw',
      origin: [75, 75],
      style: {
        image:
          'http://bom-ai-read.oss-cn-shenzhen.aliyuncs.com/makesureFile/8hJ3zT_1539670186431.png',
        width: 312,
        height: 60,
        opacity: 1
      }
    },
    {
      type: 'group',
      id: 'text',
      // rotation: Math.PI / 4,
      bounding: 'raw',
      right: 200,
      bottom: 100,
      z: -100,
      children: [
        {
          type: 'rect',
          left: 'center',
          top: 'center',
          z: -100,
          shape: {
            width: 200,
            height: 30
          },
          style: {
            fill: '#fff'
          }
        },
        {
          type: 'text',
          left: 'center',
          top: 'center',
          z: -100,
          style: {
            fill: 'rgba(0,0,0,0.1)',
            text: 'BY WWW.BOM.AI',
            font: '20px Microsoft YaHei'
          }
        }
      ]
    }
  ]
};
export const OptionsLine = {
  ...commonConfig,
  legend: {},
  dataZoom: [
    // 区域缩放
    {
      type: 'inside',
      // xAxisIndex: [0],
      yAxisIndex: [0],
      filterMode: 'none'
    }
  ],
  xAxis: {
    type: 'category',
    name: '', // x轴
    data: []
  },
  yAxis: {
    type: 'value',
    name: '',
    axisLabel: {
      formatter(value) {
        return value;
      }
    }
  },
  tooltip: {
    trigger: 'axis'
  },
  series: [
    {
      name: '',
      data: [],
      type: 'line'
    }
  ]
};
export const OptionsBar = {
  color: ['#3398DB'],
  ...commonConfig,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    }
  },
  grid: {
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: [],
    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisTick: {
      alignWithLabel: true
    }
  },
  yAxis: {
    type: 'value',
    name: '',
    axisLabel: {
      formatter(value) {
        return value;
      }
    }
  },
  series: [
    {
      name: '',
      type: 'bar',
      barWidth: '60%',
      data: []
      // data: [10, 52, 200, 334, 390, 330, 220]
    }
  ]
};
export const OptionsPie = {
  ...commonConfig,
  // title: {
  //   text: '',
  //   subtext: '',
  //   x: 'center'
  // },
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  // 图例
  //   orient: 'vertical',
  //   left: 'left',
  //   data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
  // },
  series: {
    // name: '访问来源',
    type: 'pie',
    radius: '55%',
    center: ['50%', '60%'],
    data: [],
    itemStyle: {
      emphasis: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  }
};
