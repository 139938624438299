const adoColumns = [
  {
    header: '购买时间',
    binding: 'CreateTime',
    name: 'CreateTime',
    dataType: 1,
    visible: true,
    isReadOnly: true,
    isFieldSearch: false
  },
  {
    header: '报告名称',
    binding: 'ReportName',
    name: 'ReportName',
    dataType: 1,
    visible: true,
    isReadOnly: true,
    isFieldSearch: false,
    customFilter(value, col, row) {
      if (row.FirstParam) {
        return `${value}(${row.FirstParam})`;
      }
      return value;
    }
  },
  {
    header: '金额',
    binding: 'Price',
    name: 'Price',
    dataType: 1,
    visible: true,
    isReadOnly: true,
    isFieldSearch: false
  },

  {
    header: '有效截止时间',
    binding: 'Expired',
    name: 'Expired',
    dataType: 1,
    visible: true,
    isReadOnly: true,
    isFieldSearch: false
  },
  {
    header: '用户名',
    binding: 'ByUserName',
    name: 'ByUserName',
    dataType: 1,
    visible: true,
    isReadOnly: true,
    isFieldSearch: false
  }

];

export { adoColumns };
