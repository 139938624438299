<template>
  <el-dropdown style='width: 100%; display: flex; font-size: 12px'>
    <div class='c-grid-sort' :style='{justifyContent: params.data["type"] === "day"? "center": ""}' @click='onOpen'>
      <div class='c-grid-sort_text'>
<!--        <span class='one'>{{ params.data['sort'] }}</span>-->
        <!--      <span :class='className' class='one'>{{ params.data['sort'] }}</span>-->

        <span style='display: inline-block; '>{{ params.value }}</span>
        <span class='c-grid-green two'
              v-if='params.data["Count"] < 0 && Math.abs(params.data["Count"]) > 1 && params.data["type"] !== "day"'>连降{{ params.data['Count'] === 0 ? '' : Math.abs(params.data['Count'])
          }}{{ params.data['type'] === 'week' ? '周' : '月' }}</span>
        <span class='c-grid-red two'
              v-if='params.data["Count"] > 0 && params.data["Count"] > 1 && params.data["type"] !== "day"'>连升{{ params.data['Count'] === 0 ? '' : Math.abs(params.data['Count'])
          }}{{ params.data['type'] === 'week' ? '周' : '月' }}</span>
      </div>
      <div style='display: flex;justify-content: space-between' >
<!--        <span  v-if='params.data["type"] !== "day" && params.value' style='display: inline-block; '>{{ params.value }}</span>-->
        <div style='flex-shrink: 0; width: 50px; text-align: right'>
          <span
            v-if='params.data["Rank"]' :class='params.data["Rank"] > 0 ? "c-grid-red":"c-grid-green" '>
<!--              <i style='color: #999999;font-style: inherit'></i>-->
            (<i v-if='params.data["Rank"] > 0' class='c-sort-up  t-c el-icon-top'></i>
             <i v-if='params.data["Rank"] < 0' class='c-sort-down t-c el-icon-bottom'></i>
             <i style='font-style: inherit'>{{ params.data['Rank'] === 0 ? '' : Math.abs(params.data['Rank']) }}</i>)
<!--              <i style='color: #999999;font-style: inherit'></i>-->
          </span>
        </div>

      </div>
    </div>
    <el-dropdown-menu slot='dropdown'>
      <el-dropdown-item>点击查看详情</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  name: 'GridCellSortRender',
  data () {
    return {
      className: ''
    };
  },
  methods: {
    onOpen () {
      const items = this.params.data;
      console.log(111);
      console.log(this.params);
      this.params.onOpenDialog(items);
    }
  },
  created () {
    const sort = this.params.data['RankInt'];
    const className = {
      1: 'c-circle c-circle_d9001b',
      2: 'c-circle c-circle_ee7700',
      3: 'c-circle c-circle_f9bf24'
    };
    // if (value === 1) {
    //   className = 't-c c-circle c-circle_d9001b';
    // } else if (value === 2) {
    //   className = 't-c c-circle c-circle_ee7700';
    // } else if (value === 3) {
    //   className = 't-c c-circle c-circle_f9bf24';
    // } else {
    //   className = 't-c';
    // }
    this.className = className[sort];

    // console.log(this.params)
  }
};
</script>
<style lang='scss'>
.c-grid-sort {
  flex: 1;
  display: flex;
  justify-content: space-between;

  .el-dropdown {
    font-size: 12px;
  }

}

.c-grid-sort_text {
  display: flex;
  align-items: center;

  .one {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .two {
    display: inline-block;
    margin-left: 10px;
  }
}

.c-grid-red {
  color: red;
}

.c-grid-green {
  color: green;
}

.el-dropdown-item_test {
  justify-content: left !important;
}

.c-grid-sort:hover {
  cursor: pointer;
}
</style>
