import Vue from 'vue';
import GridHeaderSortRender from '~~components/GridList/GridDataHeaderSortRender';
import GridCellLinkRender from '~~components/GridList/GridCellLinkRender';
import GridCellSortRender from '~~components/GridList/GridCellSortRender';
import GridCellRankInt from '~~components/GridList/GridCellRankInt.vue';

Vue.component('GridHeaderSortRender', GridHeaderSortRender);
Vue.component('GridCellLinkRender', GridCellLinkRender);
Vue.component('GridCellSortRender', GridCellSortRender);
Vue.component('GridCellRankInt', GridCellRankInt);
