<template>
  <div class="znl-echarts" :class="[portname === '统货估价' ? 'unifiedValuation' : '' ]">
    <span class="znl-echarts-type">报告形式：</span>
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="报表"
        v-if="displayTable"
        name="table"
      >
        <span
          slot="label"
          title="报表"
        >
          <i class="iconfont icon-report-form_table"></i>
        </span>
        <div class="report-title">{{chartsOptions.Title}}</div>
        <znl-gridmodule
          :on-init="onInit"
          :columns="columns"
          :item-source="datas"
          height="auto"
          :is-init="false"
          :loading="false"
          :total-count="totalCount"
          :page-size="pageSize"
          :page-index="pageIndex"
          :on-page-changed="onBindData"
        >
        </znl-gridmodule>
      </el-tab-pane>
      <el-tab-pane
        label="折线图"
        name="line"
        v-if="displayLine"
      >
        <span
          slot="label"
          title="折线图"
        >
          <i class="iconfont icon-report-form_line"></i>
        </span>
        <v-chart
          :options="optionsLine"
          :auto-resize="true"
          ref="line"
        />
      </el-tab-pane>
      <el-tab-pane
        label="柱状图"
        name="bar"
        v-if="displayBar"
      >
        <span
          slot="label"
          title="柱状图"
        >
          <i class="iconfont icon-report-form_histogra"></i>
        </span>
        <v-chart
          :options="optionsBar"
          :auto-resize="true"
          ref="bar"
        />
      </el-tab-pane>
      <el-tab-pane
        label="饼图"
        name="pie"
        v-if="displayPie"
      >
        <span
          slot="label"
          title="饼图"
        >
          <i class="iconfont icon-report-form_pie"></i>
        </span>
        <v-chart
          :options="optionsPie"
          :auto-resize="true"
          ref="pie"
        />
      </el-tab-pane>
    </el-tabs>

  </div>
</template>
<script >
import { toPercent } from '~~utils/utils';
import { handleColumn } from '~~utils/handleColumn';
import {
  OptionsLine,
  OptionsBar,
  OptionsPie
} from '~~assets/scripts/chartConfig';

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    portname:{
      type: String,
      default: ''
    },
    displayTable: Boolean,
    displayBar: Boolean,
    displayLine: Boolean,
    displayPie: Boolean,
    chartsOptions: {
      type: Object,
      default() {
        return {
          Datas: [],
          DownloadFileName: '',
          Title: ''
        };
      }
    },
    displayDefault: {
      type: Number,
      default: 0
    },
    displayPercentage: Boolean,
    pageIndex: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 50
    },
    totalCount: {
      type: Number,
      default: 1
    },
    onBindData: Function
  },
  data() {
    return {
      activeName: 'line',
      xName: '',
      yName: '',
      xAttr: '',
      yAttr: '',
      optionsLine: OptionsLine,
      optionsBar: OptionsBar,
      optionsPie: OptionsPie,
      columns: [],
      datas: []
    };
  },
  watch: {
    chartsOptions: {
      deep: true,
      handler(opt) {
        this.mergeOptions(opt);
      }
    },
    displayDefault(value) {
      let activeName = 'line';
      const showType = [
        { name: 'table', show: this.displayTable },
        { name: 'line', show: this.displayLine },
        { name: 'bar', show: this.displayBar },
        { name: 'pie', show: this.displayPie }
      ].find(item => {
        return item.show;
      });
      switch (value) {
        case 1:
          activeName = 'bar';
          break;
        case 2:
          activeName = 'line';
          break;
        case 4:
          activeName = 'pie';
          break;
        case 8:
          activeName = 'table';
          break;
        default:
          activeName = showType.name;
          break;
      }
      this.activeName = activeName;
    }
  },
  created () {
    console.log('初始化')
  },
  methods: {
    // 修改数据源
    mergeOptions(opt) {
      const that = this;
      // 计算xy坐标轴
      // 显示的横纵坐标命名
      const xName = [];
      const yName = [];
      // 横坐标分点
      const xAxisDatas = [];
      // 数据
      const seriesLineDatas = [];
      const seriesBarDatas = [];
      const seriesPieDatas = [];
      // 数据属性完整命名
      const xAttr = [];
      const yAttr = [];
      if (opt.Datas.length > 0) {
        const dataLength = opt.Datas.length;
        // x y 坐标名称
        Object.keys(opt.Datas[0]).forEach(item => {
          if (item.match(/^X_/)) {
            xName.push(item.substr(2));
            xAttr.push(item);
          }
          if (item.match(/^Y_/)) {
            yName.push(item.substr(2));
            yAttr.push(item);
          }
        });
        this.xName = xName[0];
        this.yName = yName[0];
        this.xAttr = xAttr[0];
        this.yAttr = yAttr[0];
        // 数据(现有数据只有一个数据线)
        seriesLineDatas.push({
          name: yName[0],
          data: [],
          type: 'line',
          label: {
            show: dataLength < 100,
            color: '#000000',
            position: 'top',
            formatter(params) {
              if (that.displayPercentage) {
                return toPercent(params.value);
              }
              return params.value;
            }
          }
        });
        let barWidth = 30;
        if (dataLength <= 20) {
          barWidth = 30;
        } else if (dataLength <= 50) {
          barWidth = 10;
        } else if (dataLength <= 100) {
          barWidth = 5;
        } else {
          barWidth = 2;
        }
        seriesBarDatas.push({
          name: '',
          data: [],
          type: 'bar',
          label: {
            show: dataLength < 100,
            color: '#000000',
            position: 'top',
            formatter(params) {
              if (that.displayPercentage) {
                return toPercent(params.value);
              }
              return params.value;
            }
          },
          barWidth
        });
        opt.Datas.forEach(item => {
          // 第一个数据线
          seriesLineDatas[0].data.push(item[yAttr[0]]);
          seriesBarDatas[0].data.push(item[yAttr[0]]);
          seriesPieDatas.push({
            name: item[xAttr[0]],
            value: item[yAttr[0]]
          });
          // 横坐标分点
          xAxisDatas.push(item[xAttr[0]]);
        });
      }
      // 公共报表属性
      // 折线报表
      this.optionsLine.title.text = opt.Title;
      this.optionsLine.xAxis.name = xName[0];
      this.optionsLine.xAxis.data = xAxisDatas;
      this.optionsLine.yAxis.name = yName[0];
      this.optionsLine.yAxis.axisLabel.formatter = value => {
        if (this.displayPercentage) {
          return toPercent(value);
        }
        return value;
      };
      this.optionsLine.series = seriesLineDatas;
      // 柱状报表
      this.optionsBar.title.text = opt.Title;
      this.optionsBar.xAxis.name = xName[0];
      this.optionsBar.xAxis.data = xAxisDatas;
      this.optionsBar.yAxis.name = yName[0];
      this.optionsBar.yAxis.axisLabel.formatter = value => {
        if (this.displayPercentage) {
          return toPercent(value);
        }
        return value;
      };
      this.optionsBar.series = seriesBarDatas;
      // 饼图
      this.optionsPie.series.data = seriesPieDatas;
    },
    async onInit() {
      console.log('onInit')
      const columns = handleColumn(this.chartsOptions.Datas);
      this.columns = columns;
      // this.getData();
    },
    getData() {
      this.datas = this.chartsOptions.Datas;
    }
  }
};
</script>
<style lang="scss">
.unifiedValuation {
  .el-tab-pane { 
    height: 400px!important;
    .el-table__body-wrapper {
      overflow-y: scroll;
      height: 350px!important;
    }
  }
}
.znl-echarts {
  width: 100%;
  // height: 600px;
  .echarts {
    width: 100%;
    height: 100%;
  }
  .znl-echarts-type {
    position: absolute;
    top: 12px;
    left: 0px;
    color: $--fontcolor-dark-3;
    font-size: 12px;
    font-weight: 600;
  }
  .report-title {
    font-weight: bold;
    color: #333;
    line-height: 20px;
  }
  .el-tab-pane {
    height: 666px;
    background-color: #fff;
  }
  .el-tabs__item {
    padding: 0 5px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
</style>
