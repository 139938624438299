<template>
  <div class="c-pane-ado">
    <div class="c-pane-ado-title">
      {{title}}
      <span class="c-pane-ado-subhead">
        {{subhead}}
      </span>
    </div>
    <slot name="title"></slot>
    <slot></slot>
  </div>
</template>
<script >
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss" scoped>
.c-pane-ado {
  text-align: left;
  padding: 0px 0 0px;
  position: relative;
  .c-pane-ado-title {
    font-size: 14px;
    color: $--fontcolor-dark-3;
    font-weight: 900;
    // padding: 10px 0;
  }
  .c-pane-ado-subhead {
    color: $--fontcolor-dark-1;
    font-size: $--font-size-small;
  }
}
</style>

