import { setOptions } from '~~components/modules/Diglog/EChart/option';
import { setData } from '~~components/modules/Diglog/EChart/data';
// import * as echarts from 'echarts';

export const setEmptyDom = () => {
  const emptyDom = document.getElementById('bom-data-tool_No');
  const eChartDom = document.getElementById('bom-data-tool_Yes');
  emptyDom.style.display = 'block';
  eChartDom.style.display = 'none';
};

export const setShowDom = () => {
  const emptyDom = document.getElementById('bom-data-tool_No');
  const eChartDom = document.getElementById('bom-data-tool_Yes');

  emptyDom.style.display = 'none';
  eChartDom.style.display = 'block';
};

export const onInitEcharts = async (kw, type, token) => {
  const newData = await setData(kw, type, token);

  if (!newData) {
    setEmptyDom();
    return false;
  } else {
    setShowDom();
  }
  const option = setOptions(newData, type, token);


  let chartDom = document.getElementById('bom-data-tool_box-eChart');
  echarts.dispose(chartDom);
  let myChart = echarts.init(chartDom);
  option && myChart.setOption(option);
};