<template>
  <div class='m-layout-grid'>
    <grid
      v-loading='loading'
      :element-loading-text='loadingText'
      :columns='columns'
      :datas='list'
      :selection='false'
      ref='list'
      headerHeight='30'
      type='base'
      @onCellClicked='onCellClicked'
      :suppressDragLeaveHidesColumns='true'
      @onRowSelected='onSelectionChanged'>
    </grid>
  </div>
</template>
<script>
import { columns } from '~~components/modules/config/table';

export default {
  name: 'TableView',
  props: {
    pageSize: Number,
    pageIndex: Number,
    pageTotal: Number,
    list: {
      type: Array,
      default () {
        return [];
      }
    },
    loading: Boolean,
    loadingText: {
      type: String,
      default () {
        return '数据加载中....';
      }
    },
    selectType: String,
    dateItems: Array
  },
  data () {
    return {
      loadingtest: true,
      columns: columns
    };
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.initColumns();
    });

  },
  methods: {
    async onSearch (page) {
      await this.$emit('onSearch', page);
    },
    onCellClicked () {

    },
    onSelectionChanged () {

    },
    initColumns () {
      let keyColumn = columns.find(item => item.field === 'RankInt');
      keyColumn.headerComponentParams = {
        selectType: this.selectType,
        onSortChange: (val) => {
          this.$emit('onSort', val);
        },
      };
      keyColumn.cellRendererParams = {
        dateItems: this.dateItems,
        onOpenDialog: (val) => {
          this.$emit('onOpen', val)
        }
      }
      this.$refs.list.changeColumns(columns);
    }
  },
  watch: {
    'dateItems': {
      handler () {
        this.initColumns();
      },
      deep: true
    },
    'selectType': {
      handler () {
        this.initColumns();
      },
      deep: true
    }
  }
};
</script>
<style lang='scss'>
.m-layout-grid {
  ::-webkit-scrollbar {
    width: 10px;
  }
}

.c-flex-box {
  display: flex;
}

</style>
