<template>
  <div class="p-AdoLeft">
    <div class="p-AdoLeft-content"
         @click="clickHandler">
      <div class="p-AdoLeft-title"
           v-if="findData.length > 0">
        发现
      </div>
      <div v-for="(item) in findData"
           :key="item.ID"
           class="p-AdoLeft-row"
           :data-id="item.ID"
           :data-name="item.Name"
           :data-type='item.type'
           :class="[id === item.ID ? 'is-active' : '']">
        <i :class="[
					'iconfont',
					item.Name === '行业景气指数' ? 'icon-leftnav_statistics' :
					item.Name === '热门型号列表' ? 'icon-leftnav_popular-models' :
					item.Name === '账户余额' ? 'icon-leftnav_wallet' :
					'icon-leftnav_top-search']"></i>
        {{item.Name}}
        <span v-if='item.type === "new"' class='p-AdoLeft-row-dec'>NEW</span>
      </div>
      <div class="p-AdoLeft-title"
           v-if="toolData.length > 0">
        工具
      </div>
      <div v-for="(item) in toolData"
           :key="item.ID"
           class="p-AdoLeft-row"
           :data-id="item.ID"
           :data-name="item.Name"
           :data-type='item.type'
           :class="[id === item.ID ? 'is-active' : '']">
        <i :class="['iconfont',
				item.Name === '库存体验' ? 'icon-leftnav_health' :
					item.Name === '订货参考' ? 'icon-leftnav_order-reference' :
					item.Name === '统货估价' ? 'icon-leftnav_valuation' :
				'icon-leftnav_leftnav_data']"></i>
        {{item.Name}}
      </div>
    </div>
  </div>
</template>
<script>
  import { getData } from '~~utils/dom';
  export default {
    name: 'AdoLeft',
    props: {
      id: String,
      portname: {
        type: String,
        default: ''
      },
      sliders: {
        type: Array,
        default() {
          return [];
        }
      },
      token: String
    },
    computed: {
      findData() {
        return this.sliders.filter(item => {
          return item.Category === 0;
        });
      },
      toolData() {
        return this.sliders.filter(item => {
          return item.Category === 1;
        });
      }
    },
    methods: {
      clickHandler(event) {
        const id = getData(event.target, 'id');
        const name = getData(event.target, 'name');
        const type = getData(event.target, 'type')
        if (id) {
          this.$emit('changeid', id, name, type);
        }
      }
    }
  };
</script>
<style lang="scss">
.p-AdoLeft {
  height: 100%;
  width: $--left-sidebar-width;
  @include position;
  overflow-y: auto;
  background-color: $--color-primary;
  color: $--color-white;
  .p-AdoLeft-title {
    line-height: 26px;
    padding-left: 15px;
    background-color: $--color-primary-light-1 !important;
  }
  .p-AdoLeft-row {
    line-height: 36px;
    font-size: $--font-size-base;
    padding-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #1f2735;
    }
    &.is-active {
      background-color: $--color-subsidiary;
    }
  }

  .p-AdoLeft-row-dec {
    display: inline-block;
    font-size: 12px;
    border: 2px solid #fbdd08;
    border-radius: 4px;
    color: #fbdd08;
    height: 14px;
    line-height: 14px;
    transform: scale(0.8);
  }
}
</style>

