<template>
  <div class='c-eChart-view'>
    <section class='c-eChart-view_header'>
      <div class='header_text'>
        <span>{{ kw }}</span>
      </div>
      <el-radio-group v-model='type' size='small' fill='#EE7700' @change='onChangeTab'>
        <el-radio-button :label='item.type' v-for='item in typeMap' :key='item.type'></el-radio-button>
      </el-radio-group>
    </section>
    <section class='c-eChart-view_box'>
      <div id='bom-data-tool_Yes'>
        <div id='bom-data-tool_box-eChart' class='bom-data-tool_box-echarts'></div>
        <div class='background_one'></div>
        <div class='background_two'></div>
        <div class='background_three'></div>

        <div class='bom-title text_one'>
          <span>搜索次数走势</span>
          <span>单位：次</span>
        </div>
        <div class='bom-title text_two'>
          <span>搜索公司数走势</span>
          <span>单位: 家</span>
        </div>

        <div class='bom-title text_three'>
          <span>价格走势</span>
          <span>单位: RMB</span>
        </div>
      </div>
      <section id='bom-data-tool_No' class='bom-data-tool_No'>
        <div>
          <span>暂无数据</span>
        </div>
      </section>
    </section>

  </div>
</template>
<script>
import { onInitEcharts } from '~~components/modules/Diglog/EChart/echart';

export default {
  name: 'EChartsView',
  props: {
    kw: {
      type: String,
      default () {
        return '';
      }
    },
    token: String
  },
  data () {
    return {
      typeMap: [
        {
          type: '周数据',
          typeIndex: 0
        },
        {
          type: '月数据',
          typeIndex: 2
        }
      ],
      type: '周数据',
      typeIndex: 0
    };
  },
  created () {


  },
  mounted () {
    // onInitEcharts(this.kw, this.typeIndex, this.token);
    console.log(this.typeIndex, 'typeIndex');
  },
  methods: {
    onChangeTab (value) {
      const item = this.typeMap.find(line => line.type === value);
      this.$nextTick(() => {
        this.typeIndex = item.typeIndex;
        onInitEcharts(this.kw, this.typeIndex, this.token);
        this.$emit('onChangTableType', value);
      });
    }
  }
};
</script>
<style lang='scss'>
.c-eChart-view {

  .header_text {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
  }

  .c-eChart-view_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .el-radio-button__inner {
      padding: 7px 20px;
    }

  }

  .c-eChart-view_box {
    position: relative;
    // 无数据
    .bom-data-tool_No {
      display: none;
      width: 100%;
      line-height: 120px;
      text-align: center;
    }

    // 图形样式
    .bom-data-tool_box-echarts {
      width: 100%;
      height: 170px;
      z-index: 1;
    }

    .background_one {
      position: absolute;
      top: 0;
      left: 0;
      width: 280px;
      height: 172px;
      background: rgba(247, 248, 250, 1);
    }

    .background_two {
      position: absolute;
      top: 0;
      left: 33.7%;
      width: 280px;
      height: 172px;
      background: rgba(247, 248, 250, 1);
    }

    .background_three {
      position: absolute;
      top: 0;
      right: 0;
      width: 280px;
      height: 172px;
      background: rgba(247, 248, 250, 1);
    }


    .bom-title {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.4500);
      box-sizing: border-box;
      width: 280px;
      height: 30px;
      font-size: 12px;

      span:first-child {
        flex: 1;
        text-align: center;
        color: #000000;
        font-weight: 700;
      }

      span:last-child {
        position: absolute;
        right: 5px;
      }
    }

    .text_one {
      position: absolute;
      top: 0;
      left: 0;
    }

    .text_two {
      position: absolute;
      top: 0;
      left: 33.7%;
    }

    .text_three {
      position: absolute;
      top: 0;
      right: 0;
    }

    .text_four {
      position: absolute;
      bottom: 41%;
      right: 0;
    }

    // 图形样式 end
  }

}
</style>