<template>
  <div class='c-grid-header-sort'>
    <section class='c-grid-header-sort_box'>
      <div class='c-grid-header-sort_box-title'>
        <div>{{ params.displayName }}</div>
        <!--        <div v-if=' params.item_1 ' class='date'>-->
        <!--          ({{ params.item_1 }})-->
        <!--        </div>-->
      </div>
      <div class='c-grid-header-sort_box-icon' v-if='params.selectType !== "day"'>
        <el-dropdown @command='handleCommand'>
          <div>
            <i class='el-icon-sort-down ' :class='sortType === 1 || sortType === 3? "is-active": "" '></i>
            <i class='el-icon-sort-up' :class='sortType === 2 || sortType === 4? "is-active": "" '></i>
          </div>
          <el-dropdown-menu slot='dropdown'>
            <el-dropdown-item
              :class='{ selected: sortType === Number(item.value) }'
              v-for='item in taskStatusMap'
              :command='item.value'
              :key='item.type'>
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>


    </section>


  </div>
</template>
<script>
export default {
  name: 'GridDataHeaderSortRender',
  data () {
    return {
      sortType: 0,  // -1：降序、0：不排序；1：升序
      taskStatusMap: [
        { value: 0, type: 'default', title: '默认排序' },
        { value: 1, type: 'up', title: '排名上升' },
        { value: 2, type: 'down', title: '排名下降' },
        { value: 3, type: 'up_1', title: '连续上升' },
        { value: 4, type: 'down_1', title: '连续下降' }
      ]
    };
  },
  methods: {
    handleCommand (val) {
      this.sortType = val;
      this.params.onSortChange(this.sortType);
    }
  },
  created () {
    console.log(this.params, 'header');
  }
};
</script>
<style lang='scss'>
.c-grid-header-sort {
  width: 100%;
  position: relative;

  .el-dropdown {
    font-size: 12px;
  }

  .is-active {
    color: #ee7700 !important;
  }
}

.c-grid-header-sort_box {
  display: flex;


  .date {
    margin: 0 8px;
  }

  .c-grid-header-sort_box-icon {
    position: absolute;
    right: 0;

  }

  .el-icon-sort-down, .el-icon-sort-up {
    color: #aeacac;
  }

  .el-icon-sort-down:before, .el-icon-sort-up:before {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    width: 7px;
  }


}

.c-grid-header-sort_box-title {
  flex: 1;
  display: flex;
  justify-content: center;
}

.selected {
  color: #409eff !important;
}
</style>
