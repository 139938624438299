<template>
  <div class='p-el-dialog'>
    <el-dialog
      title='走势分析>>'
      :visible.sync='dialogVisible'
      width='900px'
      :modal-append-to-body='false'
      close-on-click-modal>
      <div>
        <section class='dialog_box-eCharts'>
          <EChartsView ref='EChartsView'
                       @onChangTableType='onChangTableType'
                       :kw='keyWord'
                       :token='token' />
        </section>
        <section class='dialog_box-table' v-if='selectType !== "day" '>
          <div class='table_header'>
            <span class='el-dialog__title'>排名变化>></span>
            <!--            <section>-->
            <!--              <el-radio-group v-model='tableType' size='small' fill='#EE7700' @change='onChangTableType'>-->
            <!--                <el-radio-button :label='item.type' v-for='item in tableTypeMap' :key='item.type'></el-radio-button>-->
            <!--              </el-radio-group>-->
            <!--            </section>-->
          </div>
          <div class='table_box'>
            <table class='table'>
              <thead>
              <tr>
                <th style='width: 35%'>日期</th>
                <th style='width: 20%'>搜索次数</th>
                <th style='width: 20%'>排名</th>
                <th style='width: 25%'>排名变化</th>
              </tr>
              </thead>
              <tbody v-if='tableItems && tableItems.length'>
              <tr v-for='item in tableItems' :key='item.id'>
                <td style='text-align: left'>
                  <span>{{ item.date }}</span>
                  <span style='color: rgba(238, 119, 0, 1); display: inline-block; margin-left: 10px'
                        v-if='item.dateItem'>{{ item.dateItem }}</span>

                </td>
                <td style='text-align: center'>
                  <span>{{item.SearchCnt}}</span>
                </td>
                <td style='text-align: center'>
                  <span>{{ item.sort }}</span>
                </td>
                <td style='text-align: left'>
                  <span style='display: inline-block; margin-right: 10px' v-if='item.changeData > 0'>上涨 <i
                    style='color: rgba(238, 119, 0, 1); font-style: normal'>{{ item.changeData }}</i> 名</span>
                  <span style='color: rgba(238, 119, 0, 1)' v-if='item.changeItem > 0'><i
                    style='color: rgba(238, 119, 0, 1); font-style: normal'>连升{{ item.changeItem }}{{ item.type }}</i></span>

                  <span style='display: inline-block; margin-right: 10px'
                        v-if='item.changeData < 0'>下降 <i
                    style='color: green; font-style: normal'>{{ Math.abs(item.changeData) }}</i> 名</span>
                  <span style='color: green; font-style: normal'
                        v-if='item.changeItem < 0'>连降{{ Math.abs(item.changeItem) }}{{ item.type }}</span>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan='3' style='height: 80px; color: #999999; font-size: 14px'>近期无排名变化</td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
        <!--        <section class='dialog_box-sort'>-->
        <!--          &lt;!&ndash;          <div class='sort_header'>&ndash;&gt;-->
        <!--          &lt;!&ndash;            <span class='el-dialog__title'>排名>></span>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
        <!--          <div class='sort_box'>-->
        <!--            <SortEChartView-->
        <!--              :tableTypeIndex='tableTypeIndex'-->
        <!--              :kw='keyWord'-->
        <!--              :params='params'-->
        <!--              :token='token' />-->
        <!--          </div>-->
        <!--        </section>-->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import EChartsView from '~~components/modules/Diglog/EChart/EChartsView.vue';
// import SortEChartView from '~~components/modules/Diglog/SortEChart/SortEChartView.vue';
import { httpPost } from '~~assets/scripts/http';
// import { onInitSortEChart } from '~~components/modules/Diglog/SortEChart/SortEChart';
import { onInitEcharts } from '~~components/modules/Diglog/EChart/echart';
import { filterDate } from '~~utils/utils';

const CONFIG_URL = {
  weekInfo: 'webSearchCnt/GetWebSearchWeekInfo',
  monthInfo: 'webSearchCnt/GetWebSearchMonthInfo'
};

export default {
  name: 'DialogView',
  components: {
    EChartsView
    // SortEChartView
  },
  props: {
    token: String,
    selectType: String
  },
  data () {
    return {
      dialogVisible: false,
      tableTypeMap: [
        {
          type: '周数据',
          typeIndex: 0
        },
        {
          type: '月数据',
          typeIndex: 1
        }
      ],
      tableType: '周数据',
      tableTypeIndex: 0,
      tableItems: [],

      params: {
        Model: '',
        EndTime: '',
        YearAndMonth: '',
        WeekCount: '',
        MonthCount: ''
      },
      keyWord: ''
    };
  },
  methods: {
    async onOpen (data) {
      // 初始化数据
      this.tableTypeIndex = 0;
      this.tableType = '周';


      // 处理参数keyWord params: model,
      this.keyWord = data.Model;
      this.params = data;

      this.dialogVisible = true;

      await this.onInitData();
      await this.onInitEChartInfo();
      this.$refs.EChartsView.typeIndex = 0;
      this.$refs.EChartsView.type = '周数据';
    },
    async onInitData () {
      await this.setInfoData();
    },
    async onInitEChartInfo () {
      await onInitEcharts(this.keyWord, 0, this.token);
      // await onInitSortEChart({
      //   tableTypeIndex: this.tableTypeIndex,
      //   params: this.params,
      //   token: this.token
      // });
    },

    // 处理表格数据
    async getInfoData () {
      const type = this.tableTypeIndex;
      const url = type === 0 ? CONFIG_URL.weekInfo : CONFIG_URL.monthInfo;
      const params = {
        ...this.params
      };

      try {
        const res = await httpPost(this.token, url, params);
        let data = null;
        if (res.Code === 200) {
          data = res.Result;
        }
        return data;
      } catch (e) {
        throw e;
      }

    },
    async setInfoData () {
      const tableData = await this.getInfoData();

      if (tableData) {
        this.tableItems = tableData.map((item, index) => {
          if (this.tableTypeIndex === 0) {
            const startDate = filterDate(item.StartTime, 'yyyy/MM/dd');
            const endDate = filterDate(item.EndTime, 'yyyy/MM/dd');
            return {
              id: item['ID'],
              type: '周',
              sort: item['RankInt'],
              dateItem: index === 0 ? '上周' : '',
              date: `${startDate} - ${endDate}`,
              changeData: item['RankCompareLastWeek'],
              changeItem: item['WeekCount'],
              SearchCnt: item['SearchCnt']
            };
          }

          if (this.tableTypeIndex === 1) {
            return {
              id: item['ID'],
              type: '月',
              sort: item['RankInt'],
              dateItem: index === 0 ? '上月' : '',
              date: item['YearAndMonth'],
              changeData: item['RankCompareLastMonth'],
              changeItem: item['MonthCount'],
              SearchCnt: item['SearchCnt']
            };
          }

        });
      }
    },
    onChangTableType (value) {

      console.log(value, 12312);


      const item = this.tableTypeMap.find(line => line.type === value);
      const that = this;
      this.tableTypeIndex = item.typeIndex;


      this.$nextTick(async () => {
        // const items = {
        //   tableTypeIndex: this.tableTypeIndex,
        //   params: this.params,
        //   token: this.token
        // };
        // await onInitSortEChart(items);
        await that.onInitData();
      });
    }
  },
  created () {
    console.log('已经加载了', 'created');
  },
  mounted () {
    console.log('已经加载了', 'mounted');
  }
};
</script>

<style lang='scss'>
.p-el-dialog {


  // dialog 样式
  .el-dialog__title {
    font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 1);
  }

  .el-dialog__title:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 16px;
    background: #EE7700;
    border-radius: 2px 2px 2px 2px;
    margin-right: 4px;
  }

  .el-dialog__body {
    padding-top: 0;
  }

  .dialog_box-table {
    margin: 10px 0 8px;

    .table_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .el-radio-button__inner {
        padding: 7px 20px;
      }
    }

    .table_box {
      .table {
        width: 100%;
        border-collapse: collapse;
      }

      th, td {
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
        border: 1px solid #d9dcde;
        padding: 0 8px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 1);

      }

      th {
        height: 40px;
        background: #E8F3FF;
      }

      td {
        height: 36px;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
</style>