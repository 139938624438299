import { filterDate } from '~~utils/utils';
// import * as echarts from 'echarts';

const setList = (list = [], type) => {
  return list.reduce((key, value) => {
    if (type === 0) {
      value[0] = filterDate(value[0], 'MM.dd');
    }
    if (type === 2) {
      value[0] = filterDate(value[0], 'yy-MM');
    }
    key['dateList'].push(value[0]);
    key['valueList'].push(value[1]);
    return key;
  }, { dateList: [], valueList: [] });
};

export const graphicTextList = [
  {
    type: 'text',
    top: '20%',
    left: '22%',
    style: {
      text: '暂无数据'
    }
  },
  {
    type: 'text',
    top: '20%',
    right: '22%',
    style: {
      text: '暂无数据'
    }
  },
  {
    type: 'text',
    bottom: '25%',
    left: '22%',
    style: {
      text: '暂无数据'
    }
  }
];
export const graphicImgList = [
  {
    id: 'echart_1',
    type: 'image',
    style: {
      image: 'http://static.bom.ai/assets/img/bg_znl.png',
      width: 180,
      x: 50,
      y: 10
    }
  },
  {
    id: 'echart_2',
    type: 'image',
    style: {
      image: 'http://static.bom.ai/assets/img/bg_znl.png',
      width: 180,
      x: 350,
      y: 10
    }
  },
  {
    id: 'echart_3',
    type: 'image',
    style: {
      image: 'http://static.bom.ai/assets/img/bg_znl.png',
      width: 180,
      x: 650,
      y: 10
    }
  }
];


export const setOptions = (newData, type) => {
  const echarts_1 = setList(newData.echarts_data_1, type);
  const echarts_2 = setList(newData.echarts_data_2, type);
  const echarts_3 = setList(newData.echarts_data_3, type);
  let echarts_4 = setList(newData.echarts_data_4, type);

  echarts_4['valueList'] = echarts_4['valueList'].map(item => {
    if (item >= 50) {
      return 50;
    }
    return item;
  });


  const isEcharts = {
    isEcharts_1: !!echarts_1['valueList'].length,
    isEcharts_2: !!echarts_2['valueList'].length,
    isEcharts_3: !!echarts_3['valueList'].length,
    isEcharts_4: !!echarts_4['valueList'].length
  };
  let graphicList = [];
  Object.keys(isEcharts).forEach((item, index) => {
    if (isEcharts[item]) {
      graphicList.push(graphicImgList[index]);
    } else {
      graphicList.push(graphicTextList[index]);
    }
  });


  return {
    title: [],
    tooltip: {
      trigger: 'axis'
    },
    graphic: graphicList,
    xAxis: [
      {
        id: 'one',
        data: echarts_1['dateList'],
        type: 'category',
        boundaryGap: false,
        gridIndex: 0,
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        }
      },
      // 三和二调换位置，调整日期 2023-02-28 ， 调整人:chowting
      {
        id: 'three',
        data: echarts_3['dateList'],
        type: 'category',
        boundaryGap: false,
        gridIndex: 2,
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        }
      },
      {
        id: 'two',
        data: echarts_2['dateList'],
        type: 'category',
        boundaryGap: false,
        gridIndex: 1,
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitNumber: 3,
        gridIndex: 0,
        axisLabel: {
          formatter: function(value) {
            let newValue = value;
            if (newValue >= 1000) {
              newValue = parseFloat((newValue / 1000).toString()) + 'k';
            }
            return newValue;
          }
        },
        min: function() {
          // return value.min;
        }
      },

      {
        type: 'value',
        splitNumber: 3,
        gridIndex: 2,
        axisLabel: {
          formatter: function(value) {
            let newValue = value;
            if (newValue >= 1000) {
              newValue = parseFloat((newValue / 1000).toString()) + 'k';

            }
            return newValue;
          }
        },
        min: function() {
          // return value.min;
        }
      },
      {
        type: 'value',
        splitNumber: 3,
        gridIndex: 1
      }
    ],
    grid: [
      { left: '4%', top: '17%', width: '27%', height: '65%' },
      { left: '37.5%', top: '17%', width: '27%', height: '65%' },
      { right: '1.5%', top: '17%', width: '27%', height: '65%' }
    ],
    series: [
      {
        type: 'line',
        symbol: 'circle',
        sampling: 'lttb',
        smooth: true,

        symbolSize: 3,
        data: echarts_1['valueList'],
        xAxisIndex: 0,
        yAxisIndex: 0,
        lineStyle: {
          width: 0.5
        },
        itemStyle: {
          color: 'rgb(255, 70, 131)'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(242, 84, 84, 1)'
            },
            {
              offset: 1,
              color: 'rgba(255, 77, 77, 0)'
            }
          ])
        }
      },
      // 三和二调换位置，调整日期 2023-02-28 ， 调整人:chowting
      {
        type: 'line',
        symbol: 'circle',
        sampling: 'lttb',
        smooth: true,
        // showSymbol: false,
        symbolSize: 3,
        data: echarts_3['valueList'],
        xAxisIndex: 2,
        yAxisIndex: 2,
        itemStyle: {
          color: 'rgba(124, 181, 236, 1)'
        },
        lineStyle: {
          width: 0.5
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(124, 181, 236, 1)'
            },
            {
              offset: 1,
              color: 'rgba(124, 181, 236, 0)'
            }
          ])
        }
      },
      {
        type: 'line',
        symbol: 'circle',
        sampling: 'lttb',
        smooth: true,
        // showSymbol: false,
        symbolSize: 3,
        data: echarts_2['valueList'],
        xAxisIndex: 1,
        yAxisIndex: 1,
        itemStyle: {
          color: 'rgba(238, 119, 0, 1)'
        },
        lineStyle: {
          width: 0.5
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(236, 164, 124, 1)'
            },
            {
              offset: 1,
              color: 'rgba(236, 165, 124, 0)'
            }
          ])
        }
      }
    ]
  };
};
