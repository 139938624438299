export const handleColumn = datas => {
  if (datas.length === 0) {
    return [
      {
        header: '无数据',
        binding: '无数据',
        name: '无数据',
        dataType: 1,
        visible: true
      }
    ];
  }
  const fullData = datas[0];
  const columns = [];
  const urlColumns = [];
  for (const key in fullData) {
    if (fullData.hasOwnProperty(key)) {
      // if (key.match(/^X_/) || key.match(/^Y_/)) {
      const HasPrefix = key.match(/^X_/) || key.match(/^Y_/);
      const columnItem = {
        header: HasPrefix ? key.substr(2) : key,
        binding: key,
        name: key,
        dataType: 1,
        visible: !/_URL$/.test(key)
      };
      columns.push(columnItem);
      // }
      if (/_URL$/.test(key)) {
        urlColumns.push(key);
      }
    }
  }
  if (urlColumns.length > 0) {
    urlColumns.map(item => {
      columns.map(col => {
        const HasPrefix = col.binding.match(/^X_/) || col.binding.match(/^Y_/);
        // if (
        //   (HasPrefix ? col.binding.substr(2) : col.binding) ===
        //   item.substr(0, item.length - 4)
        // ) {
        col.renderCell = (h, obj) => {
          const row = obj.row;
          const column = obj.column;
          let linkContent = '';
          for (const keyname in row) {
            if (/_URL$/.test(keyname) && row[keyname]) {
              linkContent = row[keyname];
            }
          }
          // 找出以_URL结尾的列
          if (
            (HasPrefix ? col.binding.substr(2) : col.binding) ===
            item.substr(0, item.length - 4)
          ) {
            return h(
              'a',
              {
                attrs: {
                  href: linkContent,
                  target: '_blank'
                },
                style: {
                  color: '#409EFF',
                  textDecoration: 'underline'
                }
              },
              row[column.binding]
            );
          } else {
            return h('span', row[column.binding]);
          }
        };
        // }
      });
    });
  }
  return columns;
};
