let CONFIG = {
  API_URL: 'https://api.bom.ai/api/v1/',
  RES_URL: 'https://resources.bom.ai/api/v1/',
  YUN_URL: 'https://api.bom.ai/asyncapi/v1'
};
let TestConfig = {
  API_URL: 'http://test.bom.ai:8094/api/v1/',
  RES_URL: 'http://test.bom.ai:8081/api/v1/',
  YUN_URL: 'https://api.bom.ai/asyncapi/v1'
};
let DevConfig = {
  API_URL: 'http://192.168.1.10:8004/api/v1/',
  RES_URL: 'http://192.168.1.10:8004/api/v1/',
  YUN_URL: 'https://api.bom.ai/asyncapi/v1'
};

const HOSTNAME = location.hostname;

if (/^(192\.168\.1\.10)/.test(HOSTNAME) || /^(localhost)/.test(HOSTNAME)) {
  console.log(233, 1312123)
  CONFIG = DevConfig;
}
if (/^[test]/.test(HOSTNAME)) {
  CONFIG = TestConfig;
}

// CONFIG = TestConfig;
export { CONFIG };
